import { css } from '@emotion/react';
import useStyles from '@helpers/hooks/useStyles';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	min-height: calc(100% - 30px);

	.form {
		min-width: 400px;
		max-width: 400px;
	}

	.submit {
		margin: ${theme.spacing(3, 0, 1)};
	}
`;
